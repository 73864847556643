import React, { useEffect, useState } from "react";
import Header from "../assets/quote_header_narrow_.jpg";
import NarrowBg from "../assets/bid_header_bg.png";
import {
  Button,
  Image,
  Container,
  Row,
  Col,
  Jumbotron,
  Card,
  Alert,
  DropdownButton,
  Dropdown,
  FormControl,
  Form,
} from "react-bootstrap";
import quoteService from "../dataaccess/QuoteService";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import Markup from "./inputs/Markup";

const Home = () => {
  const queryParams = useLocation().search;
  const [link, setLink] = useState(new URLSearchParams(queryParams).get("b"));

  const [showButtons, setShowButtons] = useState(false);
  const [isDeclineBid, setDeclineBid] = useState(false);
  const [showPrintButtons, setShowPrintButtons] = useState(false);
  const [bidHeader, setBidHeader] = useState(null);
  const [statusList] = useState([
    "Approved",
    "Declined",
    "Cancelled",
    "Added To S Check",
  ]);

  const [declineReasons, setDeclineReasons] = useState([]);

  const [cardMessage, setCardMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [declineOption, setDeclineOption] = useState("");
  const [showReasonText, setShowReason] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [report, setReport] = useState(
    "<table CELLSPACING='0' CELLPADDING='10' style='border:2px solid #000000;' WIDTH='100%' height='95%' ALIGN='CENTER' bgcolor='#FFFFFF' bordercolor='#000000'>" +
      "<tr><td width='100%' align='center' valign='top'><br /><table CELLSPACING='0' CELLPADDING='5' BORDER='0' WIDTH='98%' ALIGN='CENTER' bgcolor='#FFFFFF' valign='top'>" +
      "<tr VALIGN='TOP'><td width='100%' style='font-size: 10px;padding-bottom:5px;' valign='top' align='center'><IMG SRC=https://scheckcenter.com/images/4078.jpg class='img-responsive'></td>" +
      "</tr></table><br><hr width='100%' color='#000000'><br>"
  );

  const [messageKeyPair] = useState([
    {
      status: "Approved",
      title: "This Quote has been Accepted",
      message:
        "We look forward to working with you. Should you have any questions, please contact us at the numbers provided in the quote.",
    },
    {
      status: "Added To S Check",
      title: "This Quote has been Accepted",
      message:
        "We look forward to working with you. Should you have any questions, please contact us at the numbers provided in the quote.",
    },
    {
      status: "Declined",
      title: "This Quote has been Declined",
      message:
        "Should you have any questions or need us to provide you with another quote, please contact us at the numbers provided in the quote. Best wishes.",
    },
    {
      status: "Cancelled",
      title: "This Quote has been Cancelled",
      message:
        "Should you have any questions or need us to provide you with another quote, please contact us at the numbers provided in the quote. Best wishes.",
    },
    {
      status: "UnAuthorized",
      title: "No access to this page",
      message:
        "Not Authorized to view this page. Please contact S Check Center for help!",
    },
    {
      status: "Error",
      title: "Oh snap! You got an error!",
      message:
        "Your quote link is faulty, try again. If the error persists, please contact us. Best wishes.",
    },
    {
      status: "Accept",
      title: "Thank you for Accepting the Quote!",
      message:
        "We look forward to working with you. Should you have any questions, please contact us at the numbers provided in the quote.",
    },
    {
      status: "Decline",
      title: "Your quote has been marked as Declined!",
      message:
        "Should you have any questions or need us to provide you with another quote, please contact us at the numbers provided in the quote. Best wishes.",
    },
  ]);

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const handleSelect = (e) => {
    if (e === "Other (please fill out Other Reason field)") {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
    setDeclineOption(e);
  };

  function handleTextChange(e) {
    setReasonText(e.target.value);
  }

  const acceptBid = (event) => {
    event.preventDefault();
    setDeclineBid(false);
    setLoading(true);

    quoteService
      .acceptBid(bidHeader.bidId, {
        status: "Approved",
        awardBy: "bidsystem",
      })
      .then((response) => {
        resolveHeaderData("Accept");
        setLoading(false);
      })
      .catch((err) => {
        const data = getMessage("Error");

        console.error(`Accept quote has ${err}`)

        if (data != null || data != undefined) {
          setShowAlert(true);
          setAlertMessage(data);
        }

        setLoading(false);
      });
  };

  const declineBid = (event) => {
    event.preventDefault();
    if (
      declineOption === "Other (please fill out Other Reason field)" &&
      reasonText.trim() === ""
    ) {
      let valErrors = [];
      valErrors.push("reasonText");
      setErrors(valErrors);

      return;
    }
    setLoading(true);

    quoteService
      .declineBid(link, {
        declineReason: declineOption,
        declineOther: reasonText,
        status: "Declined",
        awardBy: "",
      })
      .then((response) => {
        resolveHeaderData("Decline");
        setLoading(false);
        setDeclineBid(false);
      })
      .catch((err) => {
        const data = getMessage("Error");

        console.error(`Decline Quote header has ${err}`)

        if (data != null || data != undefined) {
          setShowAlert(true);
          setAlertMessage(data);
        }

        setLoading(false);
      });
  };

  const showDeclineView = () => {
    setDeclineBid(true);
  };

  const printPdf = (button) => {
    setDeclineBid(false);
  };

  function downloadPdf() {
    setDeclineBid(false);
  }

  const resolveHeaderData = (respObj) => {
    const data = getMessage(respObj);
    if (data != null || data != undefined) {
      setCardMessage(data);
    }
    setShowButtons(false);
    setShowPrintButtons(true);
  };

  const getBidHeaderByLink = () => {
    setLoading(true);
    quoteService
      .getBidByLink(link)
      .then((response) => {
        setBidHeader(response.data);
        console.log(response.data.status)
        if (response.data != null) {
          getReport(response.data.bidId);
          if (statusList.includes(response.data.status)) {
            const data = getMessage(response.data.status);
            if (data != null || data != undefined) {
              setCardMessage(data);
            }
            setShowButtons(false);
            setShowPrintButtons(true);
          } else {
            setShowButtons(true);
            setShowPrintButtons(false);
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        const data = getMessage("Error");

        console.error(`Get Quote header has ${error}`)

        if (data != null || data != undefined) {
          setShowAlert(true);
          setAlertMessage(data);
        }

        setLoading(false);
      });
  };

  const getMessage = (status) => {
    console.log(status)
    return messageKeyPair.find((dt) => dt.status === status);
  };

  useEffect(() => {
    if (link == null || link == undefined || link == "") {
      const data = getMessage("UnAuthorized");

      setCardMessage(data);
    } else {
      quoteService
        .getPayerValidValuesByTye("BidDeclineReason")
        .then((response) => {
          let reasons = [];
          response.data.map((pv) => {
            reasons.push(pv.payerValidValue);
          });

          setDeclineReasons(reasons);
        })
        .catch((err) => {});
      getBidHeaderByLink();
    }
  }, []);

  const doPrintPage = () => {
    const width = window.innerWidth || 850;
    const height = window.innerHeight || 1000;
    const MarkupBuilder = `<Markup htmlString=${report}`;
    const printWindow = window.open(
      "",
      "",
      `height=${height}px,width=${width}`
    );

    printWindow.document.write(MarkupBuilder);
    new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    });
  };

  function getReport(bidId) {
    quoteService
      .generateQuoteReport(bidId)
      .then((response) => {
        setReport(response.data);
      })
      .catch((err) => {});
  }

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : (
        <Container fluid>
          <Row style={{ backgroundImage: "url(" + NarrowBg + ")" }}>
            <Col md={{ span: 2, offset: 5 }} sm={{ span: 2, offset: 5 }}>
              <Image src={Header} fluid />
            </Col>
          </Row>

          <Jumbotron fluid>
            <Container>
              {showAlert && alertMessage && (
                <Alert
                  variant="danger"
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  <Alert.Heading>{alertMessage.title}</Alert.Heading>
                  <p>{alertMessage.message}</p>
                </Alert>
              )}
              {cardMessage && (
                <Row style={{ marginBottom: "1em" }}>
                  <Col className="text-center">
                    <Card className="text-center">
                      <Card.Header
                        style={{ color: "#FFFFFF", backgroundColor: "#0858A2" }}
                      >
                        S Check Message
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>
                          <div
                            className={
                              cardMessage.status === "Added To S Check" ||
                              cardMessage.status === "Decline" ||
                              cardMessage.status === "Approved"
                                ? "inline-errormsg"
                                : ""
                            }
                          >
                            {cardMessage.title}
                          </div>
                        </Card.Title>
                        <Card.Text>{cardMessage.message}</Card.Text>
                      </Card.Body>
                      <Card.Footer
                        className="text-muted"
                        style={{ color: "#FFFFFF", backgroundColor: "#0858A2" }}
                      ></Card.Footer>
                    </Card>
                  </Col>
                </Row>
              )}

              {showButtons && (
                <>
                  <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                      <Row>
                        <Col className="text-center" xs={4.5} md={3}>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={(e) => acceptBid(e)}
                            style={{ width: "100%" }}
                          >
                            Accept Quote
                          </Button>
                        </Col>
                        <Col className="text-center" xs={4.5} md={3}>
                          <Button
                            type="button"
                            variant="primary"
                            onClick={() => showDeclineView()}
                            style={{ width: "100%" }}
                          >
                            Decline This Quote
                          </Button>
                        </Col>
                        <Col className="text-center" xs={4.5} md={3}>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={doPrintPage}
                            style={{ width: "100%" }}
                          >
                            Print Quote
                          </Button>
                        </Col>
                        <Col className="text-center" xs={4.5} md={3}>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() => downloadPdf()}
                            style={{ width: "100%" }}
                          >
                            View Pdf
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}

              {showPrintButtons && (
                <Row>
                  <Col md={{ span: 4, offset: 4 }}>
                    <Row>
                      <Col className="text-center" xs={4.5} md={6}>
                        <Button
                          variant="primary"
                          type="button"
                          onClick={doPrintPage}
                          style={{ width: "100%" }}
                        >
                          Print Quote
                        </Button>
                      </Col>
                      <Col className="text-center" xs={4.5} md={6}>
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => downloadPdf()}
                          style={{ width: "100%" }}
                        >
                          View Pdf
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>
          </Jumbotron>

          {isDeclineBid && (
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Card>
                  <Card.Body>
                    <Form onSubmit={declineBid}>
                      <Row md={{ span: 6, offset: 3 }}>
                        <Col className="text-right" md={5}>
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={
                              declineOption
                                ? declineOption
                                : "Select Decline Reason"
                            }
                            onSelect={handleSelect}
                            style={{ width: "100%" }}
                          >
                            {declineReasons.map((reason) => (
                              <Dropdown.Item eventKey={reason}>
                                {reason}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </Col>
                        {showReasonText && (
                          <Col
                            style={{ marginTop: "1em" }}
                            className="text-center"
                            md={4}
                          >
                            <FormControl
                              placeholder="Reason"
                              aria-label="Reason"
                              aria-describedby="basic-addon1"
                              onChange={handleTextChange}
                              value={reasonText}
                              style={{ width: "100%" }}
                              className={
                                hasError("reasonText")
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            <div
                              className={
                                hasError("reasonText")
                                  ? "inline-errormsg"
                                  : "hidden"
                              }
                            >
                              Please enter a value
                            </div>
                          </Col>
                        )}

                        <Col className="text-left" md={3}>
                          <Button
                            type="submit"
                            variant="secondary"
                            style={{ width: "100%" }}
                          >
                            Decline Quote
                          </Button>
                        </Col>
                      </Row>
                      {showReasonText && <Row></Row>}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <Markup htmlString={report} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Home;
