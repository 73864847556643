import React from "react";
import { Container } from "react-bootstrap";

function Loading(props) {
  return (
    <Container className="overlay">
      <div className="center">LOADING... </div>
    </Container>
  );
}
export default Loading;
