import axios from "axios";
import access from "./Access";



class QuoteService {

  getBidByLink(link) {
    return axios.get(`${access.getAPIUrl()}quotes/${link}/bid-link`);
  }

  declineBid(link, declineRequest) {
    return axios.post(
      `${access.getAPIUrl()}quotes/${link}/decline-bid`,
      declineRequest
    );
  }

  getPayerValidValuesByTye(type) {
    return axios.get(`${access.getAPIUrl()}misc/payer-valid-values/${type}`);
  }

  acceptBid(bidId, acceptRequest) {
    return axios.post(
      `${access.getAPIUrl()}quotes/${bidId}/accept-bid`,
      acceptRequest
    );
  }

  generateQuoteReport(bidId) {
    return axios.get(`${access.getAPIUrl()}misc/quote-report/${bidId}`);
  }
}

export default new QuoteService();
