import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

/**
 * @param htmlTag: html tag like div, td, span
 * @param htmlString: string with html
 * @param rest: other html attributes of [htmlTag]
 * @returns {*|Element} JSX
 * @constructor
 */
const Markup = ({ htmlTag, htmlString, ...rest }) => {
  const custom = rest || {};
  custom.dangerouslySetInnerHTML = { __html: htmlString };
  return React.createElement(htmlTag, custom);
};

Markup.propTypes = {
  htmlTag: PropTypes.string,
  htmlString: PropTypes.string
};

Markup.defaultProps = {
  htmlTag: 'div',
  htmlString: ''
};

export default Markup;
