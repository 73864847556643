import React, { useState } from "react";

import "../styles/NavMenu.css";

const NavMenu = () => {
  
  return (
    <div></div>
   
  );
};

export default NavMenu;
