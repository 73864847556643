import React from "react";
import NavMenu from "./NavMenu";

const Layout = (props) => {
  return (
    <div>
      <NavMenu />
      <div>{props.children}</div>
    </div>
  );
};
export default Layout;
